@font-face {
  font-family: "Acumin Pro Medium";
  src: local("Acumin Pro Medium"), url(./fonts/Acumin\ Pro\ Medium.otf) format("opentype");
}
:root {
  --logo-color: #ed413d;
  --sec-color: #F6F5F5;
  --ter-color: #F6F6FB;
  --acc-color: #7ab8b1;
  --font-color: #1b2d3a;
  --font-sec: #4e606d;

  --white: #e7e8e8;
  --graphite: #484b4d;
  --brass: #a68352;
  --bronze: #514d48;
  --stainless: #b5b1a9;

  --default: var(--white);

  --trim-color: var(--default);

  --cube-size: 5em;
  --cube-color: var(--default);
}

@media screen and (max-width: 750px) {
  #menu-panel {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: 400px;
    background-color: var(--ter-color);
    transition: margin 0.5s;
    margin: 0px 0px -533px 0px;
  }
  #menu-panel.open {
    position: relative;
    margin: 0;
  }
  #recep-choice, #data-choice {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    background-color: var(--ter-color);
    transition: margin 0.5s;
    margin: -50vh 0 0 0;
    padding: 20px;
    z-index: 10;
  }
  #build {
    flex-direction: column;
  }
  .cord, .cube {
    transform: rotate(90deg);
  }
  .cube > p {
    transform: rotate(-90deg);
  }
}
@media screen and (min-width: 750px) {
  #menu-panel {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 300px;
    height: 100vh;
    background-color: var(--ter-color);
    transition: margin 0.5s;
    margin: 0 0 0 -300px;
  }
  #menu-panel.open {
    margin: 0;
  }
  #recep-choice, #data-choice {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 30vh;
    background-color: var(--ter-color);
    transition: margin 0.5s;
    margin: -30vh 0 0 0;
    z-index: 10;
  }
  #build {
    flex-direction: row;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Acumin Pro Medium", "Segoe UI", Arial, sans-serif;
  line-height: 1.4;
  color: var(--font-color);
  background: #fff;
  height: 100vh;
}
#header {
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  min-height: 20vh;
}
#logo {
  margin: 0 0 0 15px;
  background-color: var(--logo-color);
}
#logo > a {
  width: 188px;
  height: 142px;
}
#title {
  justify-self: flex-start;
}
.toc-item {
  margin: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--acc-color);
}
.toc-active {
  color: var(--font-color);
  border-bottom: 3px solid var(--font-color);
}
#controller {
  justify-content: flex-start;
  min-height: 80vh;
}
#controller > .container:nth-child(1) {
  margin-bottom: 25px;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.hor {
  flex-direction: row;
  flex-wrap: wrap;
}
.option {
  width: calc(2 * var(--cube-size));
  height: calc(2 * var(--cube-size));
}
.cord {
  width: calc(1.5 * var(--cube-size));
  height: calc(1.5 * var(--cube-size));
}
.cord img {
  width: 100%;
}
.flip img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
#trim {
  position: relative;
  margin: calc(2px + (0.5 * var(--cube-size)));
  box-shadow: 
    0 0 0 1px var(--font-color),
    0 0 0 calc(0.5 * var(--cube-size)) var(--trim-color);
}
#trim-D {
  margin: calc(0.1 * var(--cube-size));
  box-shadow: 
    0 0 0 1px var(--font-color),
    0 0 0 calc(0.1 * var(--cube-size)) var(--trim-color);
  border-radius: 3px;
}
input[type="radio"] {
  display: none;
}
input[type="radio"]:checked + .label-radio {
  box-shadow: 0px 0px 5px 5px var(--acc-color);
}
input[type="radio"] + .label-radio:hover {
  box-shadow: 0px 0px 5px 5px #DDD;
}
.label-radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 10px;
  min-width: 100px;
  min-height: 100px;
  cursor: pointer;
}
.label-radio > p {
  text-align: center;
}
::placeholder {
  color: var(--acc-color);
  opacity: 1;
}
.color-choice {
  position: relative;
  width: calc(0.4 * var(--cube-size));
  height: calc(0.4 * var(--cube-size));
  border-radius: 50%;
}
.color-choice[color="white"] {
  background-color: var(--white);
}
.color-choice[color="graphite"] {
  background-color: var(--graphite);
}
.color-choice[color="default"] {
  background-color: var(--default);
}
.color-choice[color="brass"] {
  background-color: var(--brass);
}
.color-choice[color="bronze"] {
  background-color: var(--bronze);
}
.color-choice[color="stainless"] {
  background-color: var(--stainless);
}
button {
  cursor: pointer;
}
.btn-general {
  text-transform: uppercase;
  font-weight: 400;
  font-weight: 600;
  padding: 11px 22px;
  margin: 10px;
  color: var(--font-sec);
  background: #FFFFFF;
  font-size: 1rem;
  letter-spacing: .2px;
  border: solid 2px var(--font-sec);
}
.btn-general:hover {
  background-color: var(--ter-color);
  color: var(--font-color);
  border-color: var(--font-color);
}
input.btn-general, textarea.btn-general {
  text-transform: none;
}
.cube {
  width: var(--cube-size);
  height: var(--cube-size);
  border: 1px solid var(--font-color);
  background-color: var(--cube-color);
  background-size: contain;
  background-blend-mode: multiply;
  background-position-x: center;
  background-repeat: no-repeat;
}
.cube p {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #dfda93;
}
#recep-choice.open, #data-choice.open {
  margin: 0;
}
#menu-panel > p {
  padding: 10px;
}
#choices {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.menu-choice {
  position: relative;
  margin: 10px;
  width: 75px;
  background: none;
  border: none;
}
.menu-choice img {
  margin: 10px;
  width: 90%;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250%;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 80%;
  margin-left: -80%;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}
/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}